<template>
  <div class="login-wrapper">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8">
          <div class="card login-form-card">
            <div class="card-body login-form" v-if="!isLoading">
              <div class="text-center login-form-header" v-if="post === false">
                <h4>Reset Password</h4>
                <div class="login-header-text">Need a new password? Enter your email below and we'll send you a link to reset your Local Global password</div>
              </div>
              <div class="text-center login-form-header" v-else>
                <h4>Thanks!</h4>
                <div class="login-header-text">Please now check your email!</div>
              </div>
              <form>
                <div class="form-group row mb-2">
                  <div class="offset-md-2 col-md-8">
                    <label for="email" class="col-md-12 pl-0 col-form-label text-md">Email Address</label>
                    <input id="email" type="email" class="form-control"  name="email" v-model="email" required autocomplete="email" autofocus>

                  </div>
                </div>


                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-2 forgotlink row">
                    <button type="button" @click="reset()" class="btn btn-primary w-100 mb-2 col-12">
                      Request Link
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body loading-spinner" v-else>
                <div class="loader">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // import axios from './main';
  import axios from "../main";

  export default {
    name: "Reset",
    data() {
      return {
        isLoading: false,
        post: false,
        email: null
      }
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to);
      // console.log(from);
      // console.log(next);
      next(vm => {
        vm.prevRoute = from
      })
    },
    mounted() {
      if(this.userSignedIn)
      {
        this.$router.push('/user/order-history');
      }
    },
    computed: {
      userSignedIn(){
        return this.$store.getters.userSignedIn;
      },
    },
    methods: {
      reset() {
        this.isLoading = true;
        let self = this;
        let config = {
          headers: {
            'content_type': 'application/x-www-form-urlencoded',
          }
        }
        axios.post('/api/reset-password/request', {
          'email': self.email
        }, config).then(function () {
          self.isLoading = false
          self.post = true
          self.$toastr.s("Check your Email!",'If an account exists with this email address, you will receive an email shortly!');
        }).catch(function (e){
          self.$toastr.e("Something went Wrong", e);
        });
      }
    }
  }
</script>

<style scoped lang="scss">
  .login-wrapper{
    height:  calc(100vh - 76px);
    text-align: left;
  }


  .login-form{
    .col-form-label{
      font-size: 12px;
      padding-bottom:3px;
    }
    .login-form-header{
      .login-header-text{
        font-size: 14px;
        font-weight: 700;
        color: #070314;
        margin-top: -8px;
        padding-bottom: 30px;
      }
      h4{
        font-size: 20px;
        font-weight: 600;
        padding-bottom: -3px;
        color: #070314;
      }
    }
    input{
      border-radius: 8px;
    }
    .btn{
      border-radius: 8px;
    }
    .no-account-line{
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 100%;
      margin-bottom: 0 !important;
    }
  }
  .login-form-card{
    border-radius: 1rem !important;
    margin-top: 50px;
  }
  .forgotlink{
    @media(max-width: 768px)
    {
      margin-right: 0;
      margin-left: 0;
    }
    a{
      color: #A3AFC0;
    }
  }
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 8px;
  }
  .separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  .separator::before {
    margin-right: .25em;
  }
  .separator::after {
    margin-left: .25em;
  }

  .btn-facebook{
    background-color: #1877F2;
    border-radius: 8px;
    color: white;
    img{
      height: 24px;
      margin-right: 3px;
    }
  }
  .btn-google{
    background-color: #4285F4;
    border-radius: 8px;
    color: white;
    img{
      height: 24px;
      margin-right: 12px;
    }
  }
  .btn-apple{
    padding-top: 0;
    padding-bottom: 0;
    background-color: #000000;
    border-radius: 8px;
    color: white;
    img{
      height: 40px;
      margin-right: 4px;
      margin-left: -10px;
    }
  }
  .sign-in-button{
    border-radius: 8px;
    margin: 8px;
    width: calc(33% - 16px);
    height: 40px;
    padding-left: 2px;
  }
  .loading-spinner{
     height: 490px;
  }
</style>