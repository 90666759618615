<template>
  <div class="login-wrapper">
    <back-to-venue-button class="text-center"></back-to-venue-button>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8">
          <div class="card login-form-card">
            <div class="card-body login-form" v-if="!isLoading">
              <div class="text-center login-form-header" v-if="post === false">
                <h4>Create New Account</h4>
                <div class="login-header-text">Welcome! Please fill out the form below to get started.</div>
              </div>
              <div class="text-center login-form-header" v-else>
                <h4>Thanks!</h4>
                <div class="login-header-text">You are now being signed in. Welcome to Local Global!</div>
              </div>
              <form>

                <div class="form-group row mb-2 justify-content-center pb-3 border-bottom">
                  <b-col xl="8">
                    <label class='top-label' for="name">Enter your name</label>
                    <b-form-input class="" id='name' v-model="userdetails.name" placeholder="Required"></b-form-input>
                  </b-col>
                  <b-col xl="8" class="pt-2">
                    <label class='top-label' for="email">Enter your email</label>
                    <b-form-input class="" id='email' v-model="userdetails.email" placeholder="Required"></b-form-input>
                    <label class="top-label red-error" v-show="errors.email">{{errors.email}}</label>
                  </b-col>
                  <b-col xl="8" class="pt-2">
                    <label class='top-label' for="phone">Enter your phone (optional)</label>
                    <b-form-input class="" id='phone' v-model="userdetails.phone" placeholder="Optional"></b-form-input>
                  </b-col>
<!--                  <b-col xl="8" class="pt-2">-->
<!--                    <label class='top-label' for="dob">Enter your date of birth (optional)</label>-->
<!--                    <b-form-datepicker class="" id='dob' v-model="userdetails.dob" placeholder="Optional"></b-form-datepicker>-->
<!--                  </b-col>-->
                  <b-col xl="8" class="row pt-2 pl-0 pr-0">

                    <label class="top-label col-12">Enter your date of birth (optional)</label>
                    <b-col cols="4" class="">
                      <label class='top-label' for="dob_day">Day</label>
                      <b-form-input class="" id='dob_day' v-model="userdetails.dob.day" placeholder="01" type="number" min="1" max="31" step="1"></b-form-input>
                    </b-col>
                    <b-col cols="4" class="">
                      <label class='top-label' for="dob_month">Month</label>
                      <b-form-input class="" id='dob_month' v-model="userdetails.dob.month" placeholder="03" type="number" min="1" max="12" step="1"></b-form-input>
                    </b-col>
                    <b-col cols="4" class="">
                      <label class='top-label' for="dob_year">Year</label>
                      <b-form-input class="" id='dob_year' v-model="userdetails.dob.year" placeholder="1994"  type="number" min="1900" max="2020" step="1"></b-form-input>
                    </b-col>
                  </b-col>
                </div>
                <div class="form-group row mb-2 justify-content-center pb-3 border-bottom">
                  <b-col cols="8" class="pt-2">
                    <label class='top-label' for="password">Enter a password (6 or more characters)</label>
                    <b-form-input :type="bformtype" class="" id='password' v-model="userdetails.password" placeholder="Required"></b-form-input>
                  </b-col>
                  <b-col offset="1" cols="2" class="pt-4">
                    <b-button class="show-button" @click="showPassword"><span v-if="bformtype==='password'">show</span><span v-else>hide</span></b-button>
                  </b-col>
                  <b-col cols="8" class="pt-2">
                    <label class='top-label' for="password2">Enter the same password for confirmation</label>
                    <b-form-input :type="bformtype2" class="" id='password2' v-model="userdetails.password2" placeholder="Required"></b-form-input>
                  </b-col>
                  <b-col offset="1" cols="2" class="pt-4">
                    <b-button class="show-button" @click="showPassword2"><span v-if="bformtype2==='password'">show</span><span v-else>hide</span></b-button>
                  </b-col>
                  <b-col xl="8" class="pt-2" v-if="userdetails.password != null">
                    <div class="password-matches p-2">
                      <span class="password-matches-text" v-if="userdetails.password === userdetails.password2">Passwords Match</span>
                      <span class="no-password-matches-text" v-else>Passwords do not match</span>
                    </div>
                  </b-col>
                </div>
                <div class="form-group row mb-2 justify-content-center">
                  <b-col xl="8" class="pt-2">
                    <b-form-checkbox
                        id="marketingemail"
                        v-model="userdetails.marketing_email"
                        name="marketingemail">
                      Can we contact you by email for marketing purposes?</b-form-checkbox>
                  </b-col>
                  <b-col xl="8" class="pt-2" v-if="userdetails.phone != null">
                    <b-form-checkbox
                        id="marketingphone"
                        v-model="userdetails.marketing_phone"
                        name="marketingphone">
                      Can we contact you by phone for marketing purposes?</b-form-checkbox>
                  </b-col>
                </div>


                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-2 forgotlink row">
                    <button type="button" :disabled="!formIsValid" @click="register()" class="btn btn-primary w-100 mb-2 col-12">
                      Sign Up
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body loading-spinner" v-else>
                <div class="loader">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // import axios from './main';

  import axios from "../main";
  import BackToVenueButton from "@/components/BackToVenueButton";
  export default {
    components: {BackToVenueButton},
    name: "Reset",
    data() {
      return {
        isLoading: false,
        errors:{
          email: null
        },
        post: false,
        bformtype2: 'password',
        bformtype: 'password',
        userdetails: {
          name: null,
          email: null,
          phone: null,
          dob: {
            day: null,
            month: null,
            year: null
          },
          marketing_email: false,
          marketing_phone: false,
          password: null,
          password2: null
        }
      }
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to);
      // console.log(from);
      // console.log(next);
      next(vm => {
        vm.prevRoute = from
      })
    },
    mounted() {
      if(this.userSignedIn)
      {
        this.$router.push('/user/order-history');
      }
    },
    computed: {
      userSignedIn(){
        return this.$store.getters.userSignedIn;
      },
      formIsValid()
      {
        return this.userdetails.name != null && this.userdetails.password != null && this.userdetails.email != null && (this.userdetails.password === this.userdetails.password2)
      },
      dateofbirth()
      {
        if(this.userdetails.dob.day  != null && this.userdetails.dob.month  != null && this.userdetails.dob.year  != null)
        {
          return this.userdetails.dob.day + "/" + this.userdetails.dob.month + "/" + this.userdetails.dob.year
        }
        else{
          return null;
        }
      }
    },
    methods: {
      showPassword(){
        if(this.bformtype === 'password')
        {
          this.bformtype = 'text';
        }
        else if(this.bformtype === 'text')
        {
          this.bformtype = 'password';
        }
      },
      showPassword2() {
        if (this.bformtype2 === 'password') {
          this.bformtype2 = 'text';
        } else if (this.bformtype2 === 'text') {
          this.bformtype2 = 'password';
        }

      },
      register() {
        if(this.formIsValid)
        {
          this.isLoading = true;
          let self = this;
          let config = {
            headers: {
              'content_type': 'application/json',
            }
          }
          axios.post('/api/customer/users/register/email', {
            'email': self.userdetails.email,
            'phone': self.userdetails.phone,
            'dob': self.dateofbirth,
            'password': self.userdetails.password,
            'name': self.userdetails.name
          }, config).then(function () {
            let config3 = {
              headers: {
                'content_type': 'application/x-www-form-urlencoded',
              }
            }
            axios.post('/oauth/token',{
              'grant_type':'password',
              'client_id': self.$store.getters.clientId,//
              'client_secret': self.$store.getters.clientSecret,// security concerns
              'username': self.userdetails.email,
              'password': self.userdetails.password,
              'scope': "",
            },config3).then(function (response) {
              if(response.data.access_token)
              {
                let config2 = {
                  headers: {
                    'Authorization': 'Bearer ' + response.data.access_token,
                  }
                }
                //we must try setting the user's access token  to what we were told first before we can fire this
                axios.get('api/user',config2).then(function (response2){
                  //we know our signed in user now works
                  //Save our USER
                  self.$store.commit('storeUser',{
                    'name': response2.data.name,
                    'email': response2.data.email,
                    'access_token': response.data.access_token,
                    'refresh_token': response.data.refresh_token
                  });
                  // console.log('Logging 160');
                  // console.log(response2.data);
                  self.$store.commit('userHasFakeEmail',response2.data.has_fake_email);

                  self.$store.commit('userSignedIn',true);
                  self.isLoading = false;
                  self.post = true
                  self.$cookies.set('user',self.$store.getters.user,null,null,null,true,"Strict");
                  if(self.$store.getters.order.venue === null)
                  {
                    self.$router.push('/user/order-history')
                  }
                  else{
                    self.$router.push('/v/'+self.$store.getters.order.venue);
                  }
                }).catch(function () {//still didn't work so we don't have a valid user
                  // console.log(e);
                  self.$store.commit('userSignedIn',false);
                  self.errors.social = "Email login failed: Invalid internal access token";
                  self.isLoading = false;
                })
              }
              else{
                self.$store.commit('userSignedIn',false);
                self.errors.social = "Email login failed: Invalid username or password";
              }
            }).catch(function () {//still didn't work so we don't have a valid user
              self.$store.commit('userSignedIn',false);
              // console.log(e)
              self.errors.social = "Internal login failed: incorrect param";
              self.isLoading = false;
            })
          }).catch(function (e){
            if(e.response.status === 403)
            {
              self.errors.email = "This email has already been used. Please go to Log In and sign in!";
              self.isLoading = false;
            }
          });
        }

      }
    }
  }
</script>

<style scoped lang="scss">
  .login-wrapper{
    height:  calc(100vh - 76px);
    text-align: left;
  }
  .red-error{
    color: red;
    font-size: 16px;
  }


  .login-form{
    .col-form-label{
      font-size: 12px;
      padding-bottom:3px;
    }
    .login-form-header{
      .login-header-text{
        font-size: 14px;
        font-weight: 700;
        color: #070314;
        margin-top: -8px;
        padding-bottom: 30px;
      }
      h4{
        font-size: 20px;
        font-weight: 600;
        padding-bottom: -3px;
        color: #070314;
      }
    }
    input{
      border-radius: 8px;
    }
    .btn{
      border-radius: 8px;
    }
    .no-account-line{
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 100%;
      margin-bottom: 0 !important;
    }
  }
  .login-form-card{
    border-radius: 1rem !important;
    margin-top: 50px;
  }
  .forgotlink{
    @media(max-width: 768px)
    {
      margin-right: 0;
      margin-left: 0;
    }
    a{
      color: #A3AFC0;
    }
  }
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 8px;
  }
  .separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  .separator::before {
    margin-right: .25em;
  }
  .separator::after {
    margin-left: .25em;
  }

  .btn-facebook{
    background-color: #1877F2;
    border-radius: 8px;
    color: white;
    img{
      height: 24px;
      margin-right: 3px;
    }
  }
  .btn-google{
    background-color: #4285F4;
    border-radius: 8px;
    color: white;
    img{
      height: 24px;
      margin-right: 12px;
    }
  }
  .btn-apple{
    padding-top: 0;
    padding-bottom: 0;
    background-color: #000000;
    border-radius: 8px;
    color: white;
    img{
      height: 40px;
      margin-right: 4px;
      margin-left: -10px;
    }
  }
  .sign-in-button{
    border-radius: 8px;
    margin: 8px;
    width: calc(33% - 16px);
    height: 40px;
    padding-left: 2px;
  }
  .loading-spinner{
     height: 490px;
  }
  .password-matches-text{
    color: #42b983;
    font-size: 12px;
  }
  .no-password-matches-text{
    color: red;
    font-size: 12px;
  }
  .show-button{
    background: none;
    color: black;
    border: none;
  }
</style>