<template>
  <div class="login-wrapper">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-5 col-lg-6 col-md-8">
          <div class="card login-form-card">
            <div class="card-body login-form" v-if="!isLoading">
              <div class="text-center login-form-header">
                <h4>Log In</h4>
                <div class="login-header-text">Order at your favourite local places</div>
              </div>
              <form>
                <div class="form-group row mb-2">
                  <div class="offset-md-2 col-md-8">
                    <label for="email" class="col-md-12 pl-0 col-form-label text-md">Email Address</label>
                    <input id="email" type="email" class="form-control" :class="{ 'errors.email': 'is-invalid'}" name="email" v-model="email" required autocomplete="email" autofocus>

                    <span class="invalid-feedback" role="alert" v-if="errors.email">
                      <strong>{{ errors.email }}</strong>
                    </span>
                  </div>
                </div>

                <div class="form-group row">

                  <div class="offset-md-2 col-md-8">
                    <label for="password" class="col-md-12 pl-0 col-form-label text-md">Password</label>
                    <input id="password" type="password" class="form-control" :class="{ 'errors.password': 'is-invalid'}" name="password" v-model="password" required autocomplete="password" @keyup.enter="login()">

                    <span class="invalid-feedback" role="alert" v-if="errors.password">
                      <strong>{{ errors.password }}</strong>
                    </span>
                  </div>
                </div>


                <div class="form-group row mb-0">
                  <div class="col-md-8 offset-md-2 forgotlink row">
                    <button type="button" @click="login()" class="btn btn-primary w-100 mb-2 col-12">
                      Sign In
                    </button>
                    <router-link class="btn btn-outline-primary w-100 mb-2 col-12" to="/sign-up">
                      Sign Up
                    </router-link>
                    <router-link class="w-100 text-center mx-auto justify-content-center col-12" to="/reset">
                      Forgot your password?
                    </router-link>
                  </div>
                </div>
              </form>
              <div class="separator">OR SIGN IN WITH</div>
              <div class="form-group row mb-2">
                <button @click="loginWithFacebook()" class="btn btn-facebook sign-in-button"><img src="@/assets/facebook.png"> Facebook</button>
                <button @click="loginWithGoogle()" class="btn btn-google sign-in-button"><img src="@/assets/google.png"> Google</button>
                <button @click="loginWithApple()" class="btn btn-apple sign-in-button"><img src="@/assets/apple.png">Apple</button>
              </div>
              <span class="invalid-feedback" role="alert" v-if="errors.social">
                      <strong>{{ errors.social }}</strong>
              </span>
            </div>
            <div class="card-body loading-spinner" v-else>
                <div class="loader">Loading...</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  // import axios from './main';
  import axios from "../main";

  export default {
    name: "Login",
    data() {
      return {
        isLoading: false,
        errors: {
          email: null,
          password: null,
          social: null
        },
        email: null,
        password: null,
        prevRoute: null,
        overwritten_redirect_url: null
      }
    },
    beforeRouteEnter(to, from, next) {
      // console.log(to);
      // console.log(from);
      // console.log(next);
      next(vm => {
        vm.prevRoute = from
      })
    },
    mounted() {
      if(this.userSignedIn)
      {
        this.$router.push(this.redirectUrl);
      }
      switch(this.$route.name){
        case 'sign-in-facebook':
          this.overwriteRedirectURL();
          this.handleFacebookSignIn();
          break;
        case 'sign-in-google':
          this.overwriteRedirectURL();
          this.handleGoogleSignIn();
          break;
        case 'sign-in-apple':
          this.overwriteRedirectURL();
          this.handleAppleSignIn();
          break;
        default:
          //If you don't arrive from a social redirect, it's likely that you are about to do one. So before you go, save the place you need to come back to...
          this.saveRedirectURL();
          // console.log('Login Routed');
          break;
      }


    },
    computed: {
      userSignedIn(){
        return this.$store.getters.userSignedIn;
      },
      redirectUrl(){
        if(this.overwritten_redirect_url != null)
        {
          return this.overwritten_redirect_url;
        }
        return this.prevRoute != null ? this.prevRoute.fullPath : '/post-login-redirect'
      }
    },
    methods: {
      saveRedirectURL()
      {
          this.$store.commit('setLoginRedirect',this.redirectUrl);
      },
      overwriteRedirectURL()
      {
          this.overwritten_redirect_url = this.$store.getters.login_redirect;
      },
      login(){
        this.isLoading = true;
        let self = this;
        let config = {
          headers: {
            'content_type': 'application/x-www-form-urlencoded',
          }
        }
        axios.post('/oauth/token',{
          'grant_type':'password',
          'client_id': self.$store.getters.clientId,//
          'client_secret': self.$store.getters.clientSecret,// security concerns
          'username': self.email,
          'password': self.password,
          'scope': "",
        },config).then(function (response) {
          if(response.data.access_token)
          {
            let config2 = {
              headers: {
                'Authorization': 'Bearer ' + response.data.access_token,
              }
            }
            //we must try setting the user's access token  to what we were told first before we can fire this
            axios.get('api/user',config2).then(function (response2){
              //we know our signed in user now works
              //Save our USER
              self.$store.commit('storeUser',{
                'name': response2.data.name,
                'email': response2.data.email,
                'access_token': response.data.access_token,
                'refresh_token': response.data.refresh_token
              });
              // console.log('Logging 160');fd
              // console.log(response2.data);
              self.$store.commit('userHasFakeEmail',response2.data.has_fake_email);

              self.$store.commit('userSignedIn',true);
              self.isLoading = false;
              self.$cookies.set('user',self.$store.getters.user,null,null,null,true,"Strict");
            }).catch(function () {//still didn't work so we don't have a valid user
              // console.log(e);
              self.$store.commit('userSignedIn',false);
              self.errors.social = "Email login failed: Invalid internal access token";
              self.isLoading = false;
            })
          }
          else{
            self.$store.commit('userSignedIn',false);
            self.errors.social = "Email login failed: Invalid username or password";
            self.isLoading = false;
          }
        }).catch(function () {//still didn't work so we don't have a valid user
          self.$store.commit('userSignedIn',false);
          // console.log(e)
          self.errors.social = "Internal login failed: incorrect param";
          self.isLoading = false;
        })
      },
      loginWithFacebook(){
        let state = this.generateId(20);
        this.$cookies.set('state',state)
        window.location.href = 'https://www.facebook.com/v9.0/dialog/oauth?client_id='+ process.env.VUE_APP_FACEBOOK_CLIENT_ID + '&redirect_uri='+ process.env.VUE_APP_FACEBOOK_REDIRECT_URI + '&state=' + state + '&response_type=token&scope=email';
      },
      loginWithGoogle(){
        let state = this.generateId(20);
        this.$cookies.set('state',state)
        window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?client_id='+ process.env.VUE_APP_GOOGLE_CLIENT_ID + '&redirect_uri='+ process.env.VUE_APP_GOOGLE_REDIRECT_URI + '&state=' + state + '&response_type=token&scope=https://www.googleapis.com/auth/userinfo.email%20https://www.googleapis.com/auth/userinfo.profile';
      },
      loginWithApple(){
        let state = this.generateId(20);
        this.$cookies.set('state',state)
        window.location.href = 'https://appleid.apple.com/auth/authorize?client_id='+ process.env.VUE_APP_APPLE_CLIENT_ID + '&redirect_uri='+ process.env.VUE_APP_APPLE_REDIRECT_URI + '&state=' + state +  '&response_type=code id_token&response_mode=fragment';

      },


       dec2hex (dec) {
        return dec < 10
                ? '0' + String(dec)
                : dec.toString(16)
        },
      generateId (len) {
        var arr = new Uint8Array((len || 40) / 2)
        window.crypto.getRandomValues(arr)
        return Array.from(arr, this.dec2hex).join('')
      },
      handleGoogleSignIn(){
        let result = window.location.hash.substring(1).split("&").reduce(function (result, item) {
          let split = item.split('=');
          result[split[0]] = split[1];
          return result;
        },{});
        // console.log(result);
        var state = this.$cookies.get('state')
        if(state && state === result.state)
        {
          this.handleSocialLogin('google',result.access_token)
        }
        else{
          alert('state fail');
        }
      },
      handleAppleSignIn(){
        let result = window.location.hash.substring(1).split("&").reduce(function (result, item) {
          let split = item.split('=');
          result[split[0]] = split[1];
          return result;
        },{});
        var state = this.$cookies.get('state')
        if(state && state === result.state)
        {
          this.handleSocialLogin('sign-in-with-apple',result.id_token)
        }
        else{
          alert('state fail');
        }
      },
      handleFacebookSignIn(){
        let self=this;
        let result = window.location.hash.substring(1).split("&").reduce(function (result, item) {
          let split = item.split('=');
          result[split[0]] = split[1];
          return result;
        },{});
        // console.log('236');
        // console.log(result);
        if(Object.keys(result).length === 4)
        {
          //we have arrived as part of the login flow so continue from here
          var state = this.$cookies.get('state')
          // console.log('219');
          // console.log(state);
          if(state && state === result.state)
          {
            this.handleSocialLogin('facebook',result.access_token)

          }
          else{
            // console.log('250');
            self.errors.social = "Facebook login failed: Invalid session token";
          }
        }
      },

      handleSocialLogin(provider, accessToken){
        let self = this;
        self.isLoading = false;
        let config = {
          headers: {
            'content_type': 'application/x-www-form-urlencoded',
          }
        }
        axios.post('/oauth/token',{
          'grant_type':'social',
          'provider': provider,
          'client_id': self.$store.getters.clientId,//
          'client_secret': self.$store.getters.clientSecret,// security concerns
          'access_token': accessToken,
          'scope': "",
        },config).then(function (response) {
          // console.log('273');
          // console.log(response.data);
          if(response.data.access_token)
          {
            let config2 = {
              headers: {
                'Authorization': 'Bearer ' + response.data.access_token,
              }
            }
            //we must try setting the user's access token  to what we were told first before we can fire this
            axios.get('api/user',config2).then(function (response2){
              // console.log('284');
              // console.log(response2.data);
              //we know our signed in user now works
              //Save our USER
              self.$store.commit('storeUser',{
                'name': response2.data.name,
                'email': response2.data.email,
                'access_token': response.data.access_token,
                'refresh_token': response.data.refresh_token
              });
              // console.log('Logging 304');
              // console.log(response2.data);
              self.$store.commit('userHasFakeEmail',response2.data.has_fake_email);
              self.$store.commit('userSignedIn',true);
              self.$cookies.set('user',self.$store.getters.user,null,null,null,true,"Strict");
            }).catch(function () {//still didn't work so we don't have a valid user
              // console.log('297');
              // console.log(e);
              self.$store.commit('userSignedIn',false);
              self.isLoading = false;
              self.errors.social = "Social login failed: Invalid internal access token";
            })
          }
          else{
            // console.log('304');
            self.$store.commit('userSignedIn',false);
            self.isLoading = false;
            self.errors.social = "Social login failed: Invalid Social token";
          }
        }).catch(function () {//still didn't work so we don't have a valid user
          // console.log('309');
          self.$store.commit('userSignedIn',false);
          // console.log(e)
          self.isLoading = false;
          self.errors.social = "Laravel login failed: incorrect param";
        })
      }

    },
    watch: {
      userSignedIn(newValue)
      {
        if(newValue === true)
        {
          this.$router.push(this.redirectUrl);
        }
        if(newValue === false)
        {
          //they logged out???
          // this.$router.push(this.loginRedirect);
        }
      }
    }
  }
</script>

<style scoped lang="scss">
  .login-wrapper{
    height:  calc(100vh - 76px);
    text-align: left;
  }


  .login-form{
    .col-form-label{
      font-size: 12px;
      padding-bottom:3px;
    }
    .login-form-header{
      .login-header-text{
        font-size: 14px;
        font-weight: 700;
        color: #070314;
        margin-top: -8px;
        padding-bottom: 30px;
      }
      h4{
        font-size: 20px;
        font-weight: 600;
        padding-bottom: -3px;
        color: #070314;
      }
    }
    input{
      border-radius: 8px;
    }
    .btn{
      border-radius: 8px;
    }
    .no-account-line{
      display: flex;
      align-items: center;
      font-size: 11px;
      line-height: 100%;
      margin-bottom: 0 !important;
    }
  }
  .login-form-card{
    border-radius: 1rem !important;
    margin-top: 50px;
  }
  .forgotlink{
    @media(max-width: 768px)
    {
      margin-right: 0;
      margin-left: 0;
    }
    a{
      color: #A3AFC0;
    }
  }
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin: 8px;
  }
  .separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #000;
  }
  .separator::before {
    margin-right: .25em;
  }
  .separator::after {
    margin-left: .25em;
  }

  .btn-facebook{
    background-color: #1877F2;
    border-radius: 8px;
    color: white;
    img{
      height: 24px;
      margin-right: 3px;
    }
  }
  .btn-google{
    background-color: #4285F4;
    border-radius: 8px;
    color: white;
    img{
      height: 24px;
      margin-right: 12px;
    }
  }
  .btn-apple{
    padding-top: 0;
    padding-bottom: 0;
    background-color: #000000;
    border-radius: 8px;
    color: white;
    img{
      height: 40px;
      margin-right: 4px;
      margin-left: -10px;
    }
  }
  .sign-in-button{
    border-radius: 8px;
    margin: 8px;
    width: calc(33% - 16px);
    height: 40px;
    padding-left: 2px;
  }
  .loading-spinner{
     height: 490px;
  }
</style>